"use client";
import React, { createContext, useContext, useReducer, ReactNode } from "react";

// Define the shape of your state
interface DisplayModeState {
  expandContent: boolean;
  viewPorts: {
    default: number;
    lg: number;
    md: number;
    sm: number;
  };
  // Add other properties as needed
}

interface DisplayModeContextProviderProps {
  children: ReactNode;
}

// Expanded action types
type GeneratorAction =
  | {
      type: "SET_VIEWING_MODE";
      payload: any;
    }
  | {
      type: "SET_EXPAND_CONTENT";
      payload: any;
    };
// Add other action types as needed

// Expanded reducer function
function generatorReducer(
  state: DisplayModeState,
  action: GeneratorAction,
): DisplayModeState {
  switch (action.type) {
    case "SET_VIEWING_MODE":
      return { ...state, viewPorts: action.payload };
    case "SET_EXPAND_CONTENT":
      return {
        ...state,
        expandContent:
          action.payload === "" ? !state.expandContent : action.payload,
      };
    default:
      return state;
  }
}

// Context creation with specific type
const DisplayModeContext = createContext<{
  state: DisplayModeState;
  dispatch: React.Dispatch<GeneratorAction>;
}>({
  state: {
    expandContent: false,
    viewPorts: {
      default: 4,
      lg: 3,
      md: 2,
      sm: 2,
    },
  },
  dispatch: () => {},
});

// Provider component
export const DisplayModeContextProvider: React.FC<
  DisplayModeContextProviderProps
> = ({ children }) => {
  const initialState: DisplayModeState = {
    expandContent: false,
    viewPorts: {
      default: 4,
      lg: 3,
      md: 2,
      sm: 2,
    },
  };

  const [state, dispatch] = useReducer(generatorReducer, initialState);

  return (
    <DisplayModeContext.Provider value={{ state, dispatch }}>
      {children}
    </DisplayModeContext.Provider>
  );
};

// Custom hook for using context
export const useDisplayModeContext = () => useContext(DisplayModeContext);
