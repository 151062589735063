import Image from "next/image";
import React from "react";
import Link from "next/link";
import { Eye } from "lucide-react";
import LikeButton from "./likeButton";
import { getLocalizedPath } from "../../shared/formatURL";

interface Post {
  _id: string;
  author: string;
  createdAt: string;
  imageUrl: string;
  likes: number;
  model: string;
  tagGroups: string[];
  updatedAt: string;
  userLiked: boolean;
  views: number;
}

interface PictureCardProps {
  data: Post;
  locale: any;
  state: any;
  firstImg: number;
}

const PictureCard: React.FC<PictureCardProps> = ({
  data,
  locale,
  state,
  firstImg,
}) => {
  const { _id, imageUrl, likes, userLiked, views } = data;

  const newURL = getLocalizedPath("post/" + _id);

  function formatViews(number: number) {
    if (number < 1000) {
      return number.toString(); // for numbers less than 1000, just convert to string
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "k"; // for thousands
    } else {
      return (number / 1000000).toFixed(1) + "m"; // for millions
    }
  }

  return (
    <>
      <div
        className={`relative h-full w-full bg-[#2B2D31] ${
          state.expandContent ? "" : "sm:rounded-md"
        } shadow-sm`}
      >
        <Link prefetch={false} href={newURL}>
          {firstImg === 0 ? (
            <Image
              className={`${
                state.expandContent
                  ? ""
                  : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
              }`}
              src={imageUrl}
              alt={_id + " | AI Porn Generator"}
              // layout="fill" // This will make the image take the size of its parent
              // objectFit="contain"
              layout="responsive"
              width={2}
              height={3}
              loading="eager"
              priority={true}
            />
          ) : (
            <Image
              className={`${
                state.expandContent
                  ? ""
                  : `sm:rounded sm:border-[0.0px] sm:border-[#3C3C3C] sm:shadow`
              }`}
              src={imageUrl}
              alt={_id + " | AI Porn Generator"}
              // layout="fill" // This will make the image take the size of its parent
              // objectFit="contain"
              layout="responsive"
              width={2}
              height={3}
              loading="lazy"
            />
          )}
        </Link>
        {state.viewPorts.default < 5 && (
          <div className="absolute bottom-0 right-0 flex flex-col items-end">
            <div className="mb-1 mr-2 w-full items-center justify-center ">
              <Link prefetch={false} href={newURL}>
                <div className="flex flex-col rounded-lg bg-[#2B2D31]/20 backdrop-blur-xl">
                  <Eye color="white" className="mx-1 my-1 h-5 w-auto" />
                  <div className="mx-auto mb-0.5 text-xs text-white">
                    {formatViews(views)}
                  </div>
                </div>
              </Link>
            </div>
            <LikeButton
              initialLikes={likes}
              id={_id}
              userLiked={userLiked}
              locale={locale}
              newURL={newURL}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PictureCard;
